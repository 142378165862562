<template>
  <section class="hero is-fullheight-with-navbar landing-hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-6 hero-text" data-aos="fade-right">
            <h1 class="title main-title">
              {{ $t("landing_hero.title") }}
            </h1>
            <h2 class="subtitle hero-subtitle">
              {{ $t("landing_hero.subtitle") }}
            </h2>
            <div class="buttons">
              <router-link to="/products" class="button is-primary is-large">
                {{ $t("landing_hero.cta_button") }}
              </router-link>
              <router-link to="/contact" class="button is-light is-large">
                {{ $t("landing_hero.contact_button") }}
              </router-link>
            </div>
          </div>
          <div class="column is-6" data-aos="fade-left">
            <figure class="image">
              <img :src="require('@/assets/hero-machine.png')" alt="Hero machine" class="hero-image" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingHero"
};
</script>

<style scoped>
.landing-hero {
  background: linear-gradient(135deg, #517ebd 0%, #2b4c7e 100%);
  color: white;
}

.main-title {
  font-size: 4rem !important;
  font-weight: 700 !important;
  line-height: 1.2;
  margin-bottom: 2rem !important;
  color: white;
}

.hero-subtitle {
  font-size: 1.5rem !important;
  line-height: 1.6;
  margin-bottom: 3rem !important;
  color: #e2e8f0;
}

.hero-text {
  padding-right: 3rem;
}

.hero-image {
  max-height: 500px;
  object-fit: contain;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));
}

.button.is-primary {
  background-color: #3182ce;
  transition: all 0.3s ease;
}

.button.is-primary:hover {
  background-color: #2c5282;
  transform: translateY(-2px);
}

.button.is-light {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: all 0.3s ease;
}

.button.is-light:hover {
  background-color: white;
  color: #2c5282;
  transform: translateY(-2px);
}

@media screen and (max-width: 768px) {
  .main-title {
    font-size: 2.5rem !important;
  }

  .hero-subtitle {
    font-size: 1.25rem !important;
  }

  .hero-text {
    padding-right: 1rem;
    text-align: center;
  }

  .buttons {
    justify-content: center;
  }
}
</style>
