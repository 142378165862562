<template>
  <div class="container">
    <div class="used-tech">
      <h1>{{ $t("used_tech.header") }}</h1>
      <hr />
      <div class="columns">
        <div class="column">
          <a href="https://www.smc.eu/en-eu" target="_blank"><img src="../assets/smc.svg" alt class="grayscale" /></a>
        </div>
        <div class="column">
          <a href="https://www.schaeffler.com.tr/content.schaeffler.tr/tr/index.jsp" target="_blank"><img
              src="../assets/fag.svg" alt class="grayscale" /></a>
        </div>
        <div class="column">
          <a href="https://www.eaton.com/us/en-us.html" target="_blank">
            <img src="../assets/eaton.svg" alt class="grayscale" /></a>
        </div>
        <div class="column">
          <a href="https://global.abb/group/en" target="_blank"><img src="../assets/abb.svg" alt
              class="grayscale" /></a>
        </div>
        <div class="column">
          <a href="https://www.se.com/ww/en/" target="_blank"><img src="../assets/schneider.svg" alt
              class="grayscale" /></a>
        </div>
      </div>

      <hr />
      <BulmaAccordion :dropdown="false" :icon="'caret'" :caretAnimation="{
        duration: '.06s',
        timerFunc: 'ease-in-out'
      }" :slide="{
            duration: '.03s',
            timerFunc: 'ease'
          }">
        <!-- The wrapper component for all the items -->
        <BulmaAccordionItem>
          <h4 class="title is-4 has-text-weight-normal" slot="title">
            {{ $t('used_tech.electronics.header') }}
          </h4>
          <ul slot="content">
            <li>{{ $t('used_tech.electronics.item1') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item2') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item3') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item4') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item5') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item6') }}</li>
            <br />
            <li>{{ $t('used_tech.electronics.item7') }}</li>
          </ul>
        </BulmaAccordionItem>
        <BulmaAccordionItem>
          <h4 class="title is-4 has-text-weight-normal" slot="title">
            {{ $t('used_tech.pneumatics.header') }}
          </h4>
          <ul slot="content">
            <li>{{ $t('used_tech.pneumatics.item1') }}</li>
            <br />
            <li>{{ $t('used_tech.pneumatics.item2') }}</li>
            <br />
            <li>{{ $t('used_tech.pneumatics.item3') }}</li>
            <br />
            <li>{{ $t('used_tech.pneumatics.item4') }}</li>
            <br />
            <li>{{ $t('used_tech.pneumatics.item5') }}</li>
          </ul>
        </BulmaAccordionItem>
        <BulmaAccordionItem>
          <h4 class="title is-4 has-text-weight-normal" slot="title">
            {{ $t('used_tech.mechanics.header') }}
          </h4>
          <ul slot="content">
            <li>{{ $t('used_tech.mechanics.item1') }}</li>
            <br />
            <li>{{ $t('used_tech.mechanics.item2') }}</li>
            <br />
            <li>{{ $t('used_tech.mechanics.item3') }}</li>
            <br />
            <li>{{ $t('used_tech.mechanics.item4') }}</li>
            <br />
            <li>{{ $t('used_tech.mechanics.item5') }}</li>
            <br />
          </ul>
        </BulmaAccordionItem>
      </BulmaAccordion>
    </div>
  </div>
</template>

<script>
import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";

export default {
  name: "UsedTech",
  data() {
    return {};
  },
  components: {
    BulmaAccordion,
    BulmaAccordionItem
  }
};
</script>

<style scoped>
li {
  font-size: 1.5rem;
  color: #185088;
  font-weight: 500;
}

h1 {
  font-size: 50px;
}

img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  max-width: 150px;
  height: auto;
  margin: 1rem;
  object-fit: contain;
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  transition: all 0.3s;
}

.used-tech {
  margin-bottom: 100px;
  margin-top: 100px;
}

.columns {
  align-items: center;
}

@media screen and (max-width: 768px) {
  img.grayscale {
    max-width: 120px;
    margin: 0.5rem;
  }
}
</style>
