<template>
  <div class="card-container">
    <div class="card has-text-centered">
      <div class="card-image">
        <div class="image-container">
          <figure :class="onebyone">
            <img :src="require('@/assets/' + image + '')" alt="" />
          </figure>
        </div>
      </div>
      <div class="content">
        <h3 class="title is-4">{{ title }}</h3>
      </div>
      <router-link v-if="whereto !== ''" :to="{ path: whereto }">
        <div class="card-footer">
          <button class="button is-inverted is-info">
            {{ $t('product_card.footer') }}
          </button>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    slidefrom: {
      type: String,
      default: "",
      required: false
    },
    onebyone: {
      type: String,
      default: "",
      required: false
    },
    whereto: {
      type: String,
      default: "",
      required: false
    }
  },
  name: "ProductCard"
};
</script>

<style scoped>
.card-container {
  padding: 0.75rem;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: white;
}

.card-image {
  padding: 0.5rem;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* Creates a 1:1 aspect ratio */
}

figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.2s;
}

.content {
  flex-grow: 1;
  padding: 1rem 0.5rem;
}

.card-footer {
  padding: 0.5rem;
  border: none;
  margin-top: auto;
}

.card-footer button {
  margin-top: 0.5rem;
}

.card-image img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .card {
    margin-bottom: 1rem;
  }

  .button.is-large {
    font-size: 1rem;
  }
}
</style>
