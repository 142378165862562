import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
// import BootstrapVue from "bootstrap-vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import AOS from "aos";
import "aos/dist/aos.css";
import VueLazyLoad from "vue-lazyload";
import titleMixin from "./mixins/titleMixin";
import i18n from "./i18n";

require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

Vue.use(Buefy);
Vue.use(VueLazyLoad);
Vue.config.productionTip = false;
Vue.mixin(titleMixin);

new Vue({
  created() {
    AOS.init();
  },

  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
