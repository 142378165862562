<template>
  <div>
    <LandingHero />
    <ProductPreview />
    <UsedTech />
  </div>
</template>

<script>
import UsedTech from "../components/UsedTech";
import ProductPreview from "../components/ProductPreview";
import LandingHero from "../components/LandingHero";

export default {
  title: "Toray Makine - Anasayfa",
  name: "Home",
  components: {
    LandingHero,
    UsedTech,
    ProductPreview
  }
};
</script>

<style scoped></style>
