<template>
  <div class="header-container">
    <header>
      <b-navbar :spaced="true" :fixedTop="true" :shadow="true">
        <template slot="brand">
          <b-navbar-item tag="router-link" :to="{ path: '/' }" class="logo-container">
            <img :src="require('@/assets/logo.svg')" alt="Logo" class="navbar-logo" />
          </b-navbar-item>
        </template>
        <template slot="end">
          <b-navbar-item tag="router-link" :to="{ path: '/' }">{{ $t('header.navbar.item1') }}</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/about' }">{{ $t('header.navbar.item2') }}</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/products' }">{{ $t('header.navbar.item3') }}</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/contact' }">{{ $t('header.navbar.item4') }}</b-navbar-item>
          <div class="select">
            <select @change="changeLan" v-model="selectedVal">
              <option v-for="(flag, i) in flags" :key="`Lang${i}`" :value="flag" :selected="selectedVal">
                {{ flag }}
              </option>
            </select>
          </div>
        </template>
      </b-navbar>
    </header>
  </div>
</template>

<script>
import i18n from "../i18n.js";

//if you want to add a language, add to langs first, flags second and changeLan third, and you're done!
export default {
  name: "MasterHeader",
  data() {
    return {
      langs: ["tr", "en"],
      flags: ["🇹🇷 TURKCE", "🇬🇧 ENGLISH"],
      selectedVal: "🇹🇷 TURKCE"
    };
  },
  methods: {
    changeLan: function () {
      switch (this.selectedVal) {
        case "🇹🇷 TURKCE":
          i18n.locale = "tr";
          break;
        case "🇬🇧 ENGLISH":
          i18n.locale = "en";
          break;
        default:
          i18n.locale = "tr";
          break;
      }
    }
  }
};
</script>

<style scoped>
.header-container {
  padding: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
}

.navbar-logo {
  width: auto;
  height: 35px !important;
  object-fit: contain;
  object-position: left center;
  max-height: none !important;
}

.navbar-item img {
  max-height: none !important;
}

/* Make logo slightly smaller on mobile */
@media screen and (max-width: 768px) {
  .navbar-logo {
    height: 45px !important;
  }
}
</style>
