<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <div class="columns">
          <div class="column">
            <p>
              <strong>{{ $t('footer.slogan') }}</strong>
              <br />
              <a :href="`${publicPath}sozlesme.pdf`" download target="_blank">{{ $t('footer.contract') }}</a>
              <br />
              <br />
              <a class="has-text-black" href="https://www.instagram.com/toraymachine/" target="_blank">
                <font-awesome-icon size="lg" :icon="['fab', 'instagram']" />
              </a>
              <a class="has-text-danger" href="" target="_blank">
                <font-awesome-icon size="lg" :icon="['fab', 'youtube']" />
              </a>
              <a class="has-text-info" href="twitter.com" target="_blank">
                <font-awesome-icon size="lg" :icon="['fab', 'twitter']" />
              </a>
            </p>
          </div>
          <div class="column">
            <h4>{{ $t('footer.contact') }}</h4>
            <a href="mailto: toraymakine@gmail.com">toraymakine@gmail.com</a>
            <p>+90 546 736 50 98</p>
            <p>Orta Mah. Kavaklar Cad. No: 15 No: 103, Adapazarı/Sakarya</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import "../icon";

export default {
  name: "MasterFooter",
  data: () => ({ publicPath: process.env.BASE_URL })
};
</script>

<style scoped>
footer {
  margin-top: 60px;
}

a {
  padding: 10px 10px 10px 10px;
}
</style>
