<template>
  <div class="container products-container">
    <div class="columns is-multiline">
      <div class="column is-4">
        <ProductCard :title="$t('product_card.title.product3')" image="pre-500.jpg" whereto="products/pre500" />
      </div>
      <div class="column is-4">
        <ProductCard :title="$t('product_card.title.product1')" image="sd-500.png" whereto="products/sd500" />
      </div>

      <div class="column is-4">
        <ProductCard :title="$t('product_card.title.product2')" image="st-1500.png" whereto="products/st1500" />
      </div>

    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard";

export default {
  name: "ProductPreview",
  components: {
    ProductCard
  }
};
</script>

<style scoped>
.products-container {
  padding: 2rem 1.5rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.column {
  padding: 0.75rem;
}

@media screen and (max-width: 768px) {
  .products-container {
    padding: 1rem;
  }

  .column {
    padding: 0.5rem;
  }
}
</style>
