<template>
  <div id="app">
    <MasterHeader />
    <router-view />
    <MasterFooter />
  </div>
</template>

<script>
import MasterHeader from "./components/Header";
import MasterFooter from "./components/Footer";

export default {
  title: "Toray Makine - Anasayfa",
  name: "App",
  components: {
    MasterHeader,
    MasterFooter
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Override Buefy/Bulma default colors */
:root {
  --primary: #3182ce;
  --link: #3182ce;
}

/* Navbar specific styles */
.navbar-item {
  color: #2c3e50 !important;
}

.navbar-item:hover {
  background-color: rgba(49, 130, 206, 0.1) !important;
  color: #3182ce !important;
}

/* Fix dropdown menu colors */
.select select {
  border-color: #3182ce;
}

.select select:hover {
  border-color: #2c5282;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #3182ce;
}

.select select:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 0.125em rgba(49, 130, 206, 0.25);
}

/* Email and regular links in content */
.content a:not(.button):not(.navbar-item) {
  color: #3182ce;
}

.content a:not(.button):not(.navbar-item):hover {
  color: #2c5282;
}

/* Selection color */
::selection {
  background: #3182ce;
  color: white;
}

::-moz-selection {
  background: #3182ce;
  color: white;
}

/* Container padding */
.container:not(.header-container) {
  padding: 1.5rem;
}

/* Add specific padding for product views if needed */
.product-view .container {
  padding: 2rem;
}
</style>
