import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Products.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  },
  {
    path: "/products/sd500",
    name: "SD500",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SD500.vue")
  },
  {
    path: "/products/st1500",
    name: "ST1500",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ST1500.vue")
  },
  {
    path: "/products/pre500",
    name: "PRE500",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PRE500.vue")
  },
  {
    path: "*", component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue")
  }
];
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

export default router;
